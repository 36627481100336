<template>
	<div>
		<ContentHeader title="Laporan Akuntansi" subTitle="Laporan Neraca" url="laporan-akuntansi" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Laporan</h3>
          </div>
          <div class="card-body">
            <div class="row align-items-center mt-2">
              <div class="col-lg-3">
                <span>Periode Bulan</span>
                <input type="month" class="form-control" v-model="periode_bulan" @change="getDataResult">
              </div>
              <div class="col-lg-4"></div>
              <div class="col-lg-5" v-if="toogleCabang">
                <label>Pilih Cabang</label>
                <multiselect 
                  v-model="cabang_select_value"
                  deselect-label="Can't remove this value"
                  track-by="nama"
                  label="nama"
                  placeholder=""
                  :options="cabang"
                  :loading="loading_cabang"
                  :allow-empty="false"
                  @update:model-value="getDataResult"
                >
                </multiselect>
              </div>
            </div>
            <p class="text-center mt-4" v-if="memuat_data">Memuat Data...</p>
            <div class="row" v-if="!memuat_data">
              <div class="col-6">
                <table class="table table-bordered table-hover mt-4">
                  <thead>
                    <th scope="col" class="text-sm">Kode</th>
                    <th scope="col" class="text-sm">Nama Account</th>
                    <th scope="col" class="text-sm">Saldo (Rp.)</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-sm p-2 font-weight-bold bg-warning" scope="row" colspan="3">ACTIVA</td>
                    </tr>
                    <tr>
                      <td class="text-sm p-2 font-weight-bold" scope="row">1-000</td>
                      <td class="text-sm p-2 font-weight-bold" scope="row">Activa</td>
                      <td class="text-sm p-2" scope="row"></td>
                    </tr>
                    <tr>
                      <td class="text-sm p-2 text-danger" scope="row">1-100</td>
                      <td class="text-sm p-2 text-danger" scope="row">Activa Lancar</td>
                      <td class="text-sm p-2" scope="row"></td>
                    </tr>
                    <!-- Looping -->
                    <tr v-for="row in aktiva_lancar" :key="row.id">
                      <td class="text-sm p-2" scope="row">{{ row.kode }}</td>
                      <td class="text-sm p-2" scope="row">{{ row.nama }}</td>
                      <td class="text-sm text-right p-2" scope="row">{{ format_nominal(row.nominal) }}</td>
                    </tr>
                    <!-- End Looping -->
                    <tr>
                      <td class="text-sm p-2 font-weight-bold" scope="row" colspan="2">Total Aktiva Lancar</td>
                      <td class="text-sm p-2 text-right">{{ format_nominal(total.total_aktiva_lancar) }}</td>
                    </tr>
                    <tr>
                      <td class="text-sm p-2 text-danger" scope="row">1-200</td>
                      <td class="text-sm p-2 text-danger" scope="row">Activa Tetap</td>
                      <td class="text-sm p-2" scope="row"></td>
                    </tr>
                    <!-- Looping -->
                    <tr v-for="row in aktiva_tetap" :key="row.id">
                      <td class="text-sm p-2" scope="row">{{ row.kode }}</td>
                      <td class="text-sm p-2" scope="row">{{ row.nama }}</td>
                      <td class="text-sm text-right p-2" scope="row">{{ format_nominal(row.nominal) }}</td>
                    </tr>
                    <!-- End Looping -->
                    <tr>
                      <td class="text-sm p-2 font-weight-bold" scope="row" colspan="2">Total Aktiva Tetap</td>
                      <td class="text-sm p-2 text-right">{{ format_nominal(total.total_aktiva_tetap) }}</td>
                    </tr>
                    <tr>
                      <td class="text-sm p-2 font-weight-bold" scope="row" colspan="2">TOTAL AKTIVA</td>
                      <td class="text-sm p-2 text-right">{{ format_nominal(total.total_aktiva) }}</td>
                    </tr>
                  </tbody>  
                </table>
              </div>
              <div class="col-6">
                <table class="table table-bordered table-hover mt-4">
                  <thead>
                    <th scope="col" class="text-sm">Kode</th>
                    <th scope="col" class="text-sm">Nama Account</th>
                    <th scope="col" class="text-sm">Saldo (Rp.)</th>
                  </thead>  
                  <tbody>
                    <tr>
                      <td class="text-sm p-2 font-weight-bold bg-warning" scope="row" colspan="3">KEWAJIBAN DAN MODAL</td>
                    </tr>
                    <tr>
                      <td class="text-sm p-2 font-weight-bold" scope="row">2-000</td>
                      <td class="text-sm p-2 font-weight-bold" scope="row">Hutang</td>
                      <td class="text-sm p-2" scope="row"></td>
                    </tr>
                    <tr>
                      <td class="text-sm p-2 text-danger" scope="row">2-100</td>
                      <td class="text-sm p-2 text-danger" scope="row">Hutang Lancar</td>
                      <td class="text-sm p-2" scope="row"></td>
                    </tr>
                    <!-- Looping -->
                    <tr v-for="row in hutang_lancar" :key="row.id">
                      <td class="text-sm p-2" scope="row">{{ row.kode }}</td>
                      <td class="text-sm p-2" scope="row">{{ row.nama }}</td>
                      <td class="text-sm text-right p-2" scope="row">{{ format_nominal(row.nominal) }}</td>
                    </tr>
                    <!-- End Looping -->
                    <tr>
                      <td class="text-sm p-2 font-weight-bold" scope="row" colspan="2">Total Hutang Lancar</td>
                      <td class="text-sm p-2 text-right">{{ format_nominal(total.total_hutang_lancar) }}</td>
                    </tr>
                    <tr>
                      <td class="text-sm p-2 text-danger" scope="row">2-200</td>
                      <td class="text-sm p-2 text-danger" scope="row">Hutang Jangka Panjang</td>
                      <td class="text-sm p-2" scope="row"></td>
                    </tr>
                    <!-- Looping -->
                    <tr v-for="row in hutang_jangka_panjang" :key="row.id">
                      <td class="text-sm p-2" scope="row">{{ row.kode }}</td>
                      <td class="text-sm p-2" scope="row">{{ row.nama }}</td>
                      <td class="text-sm text-right p-2" scope="row">{{ format_nominal(row.nominal) }}</td>
                    </tr>
                    <!-- End Looping -->
                    <tr>
                      <td class="text-sm p-2 font-weight-bold" scope="row" colspan="2">Total Hutang Jangka Panjang</td>
                      <td class="text-sm p-2 text-right">{{ format_nominal(total.total_hutang_jangka_panjang) }}</td>
                    </tr>
                    <tr>
                      <td class="text-sm p-2 font-weight-bold" scope="row">3-000</td>
                      <td class="text-sm p-2 font-weight-bold" scope="row">Modal</td>
                      <td class="text-sm p-2" scope="row"></td>
                    </tr>
                    <!-- Looping -->
                    <tr v-for="row in modal" :key="row.id">
                      <td class="text-sm p-2" scope="row">{{ row.kode }}</td>
                      <td class="text-sm p-2" scope="row">{{ row.nama }}</td>
                      <td class="text-sm text-right p-2" scope="row">{{ format_nominal(row.nominal) }}</td>
                    </tr>
                    <!-- End Looping -->
                    <tr>
                      <td class="text-sm p-2 font-weight-bold" scope="row" colspan="2">Total Modal</td>
                      <td class="text-sm p-2 text-right">{{ format_nominal(total.total_modal) }}</td>
                    </tr>
                    <tr>
                      <td class="text-sm p-2 font-weight-bold" scope="row" colspan="2">TOTAL KEWAJIBAN DAN MODAL</td>
                      <td class="text-sm p-2 text-right">{{ format_nominal(total.total_kewajiban_dan_modal) }}</td>
                    </tr>
                  </tbody>  
                </table>
              </div>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
          </div>
          <!-- /.card-footer-->
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'
import format_nominal from '@/format_nominal'
const d = new Date()
let year = d.getFullYear()
let month = d.getMonth() + 1
const periode_bulan = ref(`${year}-${month}`)

export default{
  components: {
    ContentHeader,
    Multiselect
  },
  setup(){
    const user = computed(() => store.getters['auth/user'])
    const data_result = ref([])
    const aktiva_lancar = ref([])
    const aktiva_tetap = ref([])
    const hutang_lancar = ref([])
    const hutang_jangka_panjang = ref([])
    const modal = ref([])
    const total = ref({})
    const cek_data = ref('')
    const memuat_data = ref(false)
    
    const getDataResult = async () => {
      data_result.value = []
      aktiva_lancar.value = []
      aktiva_tetap.value = []
      hutang_lancar.value = []
      hutang_jangka_panjang.value = []
      modal.value = []
      total.value = {}
      memuat_data.value = true
      cek_data.value = ''
      let bulan = ''

      if (periode_bulan.value == '') {
        bulan = 'emptyParameter'
      } else {
        bulan = periode_bulan.value
      }

      let cabang_id = ''

      if (cabang_select_value.value == '') {
        cabang_id = user.value.cabang_id
      } else {
        cabang_id = cabang_select_value.value.id
      }

      let { data } = await axios.get(`api/laporan/neraca/${cabang_id}/${bulan}`)
      console.log(data)
      if (data == 'kosong') {
        cek_data.value = 'Data Masih Kosong'
        memuat_data.value = false
        data_result.value = []
        aktiva_lancar.value = []
        aktiva_tetap.value = []
        hutang_lancar.value = []
        hutang_jangka_panjang.value = []
        modal.value = []
        total.value = {}
      } else {
        memuat_data.value = false
        cek_data.value = ''
        data_result.value = data.data
        aktiva_lancar.value = data.aktiva_lancar
        aktiva_tetap.value = data.aktiva_tetap
        hutang_lancar.value = data.hutang_lancar
        hutang_jangka_panjang.value = data.hutang_jangka_panjang
        modal.value = data.modal
        total.value = data.total
      }
    }

    const toogleCabang = ref(false)
    const cabang_select_value = ref('')
    const cabang = ref([])
    const loading_cabang = ref(true)
    const getCabang = async () => {
      let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

      if (response.data.jenis == 'pusat') {
        toogleCabang.value = true
        let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
        
        if (data != 'kosong') {
          cabang.value = data
          loading_cabang.value = false
        } else {
          cabang.value = []
          loading_cabang.value = false
        }
      }
    }

    onMounted(() => {
      getCabang()
      getDataResult()
    })

    return {
      data_result, cek_data, memuat_data, getDataResult, format_nominal, periode_bulan, aktiva_lancar, aktiva_tetap, hutang_lancar, hutang_jangka_panjang, modal, total, cabang_select_value, cabang, loading_cabang, toogleCabang
    }
  }
}
</script>